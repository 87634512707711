<template>
	<v-layout v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</v-layout>
</template>

<script>
/**
 * @displayName w-layout
 *
 * @since 0.3.2
 */
export default {
	name: 'WLayout'
}
</script>
